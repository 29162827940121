/* 倒计时mixin */
const mixinCountDown = {
  data() {
    return {
      times: {
        h: 0,
        m: 0,
        s: 0,
        hm: 0
      },
      CustomTimer: null,
      CustomTimeList:[]
    }
  },
  async mounted() {

  },
  beforeDestroy() { // 在Vue 2中使用 beforeDestroy 钩子
    this.clearTimer();
  },

  beforeUnmount() { // 在Vue 3中使用 beforeUnmount 钩子
    this.clearTimer();
  },
  methods: {
    init(){
      this.initTimer();
    },
    initTimer(){
      this.CustomTimer =setInterval(() => {
        this.times = this.getCurrDayCountdown();
        console.log('mixinCountDown CustomTimer')
      }, 1)
      this.CustomTimeList.push(this.CustomTimer)
    },
    clearTimer(){
      clearInterval(this.CustomTimer);
      if (this.CustomTimer) {
        this.CustomTimeList.forEach((item, index) => {
          clearInterval(item);
        })
        this.CustomTimeList = [];
        this.CustomTimer = 0;
      }
    },
    getCurrDayCountdown() {
      let now = new Date(); // 获取当前时间
      let hour = now.getHours(); // 时
      let min = now.getMinutes(); // 分
      let sec = now.getSeconds(); // 秒
      let hm = now.getMilliseconds(); // 秒
      hm = hm.toString().substr(0,1)

      let h = 24 - hour; // 倒计时 时
      if (min > 0 || sec > 0) {
        h -= 1
      }
      let m = 60 - min; // 倒计时 分
      if (sec > 0) {
        m -= 1
      }
      if (m == 60) {
        m = 0
      }
      let s = 60 - sec; // 倒计时 秒
      if (s == 60) {
        s = 0
      }

      h = h.toString();
      m = m.toString();
      s = s.toString();
      if (h.length == 1) {
        h = '0' + h
      }
      if (m.length == 1) {
        m = '0' + m
      }
      if (s.length == 1)
        s = '0' + s
      var result = h + ':' + m + ':' + s
      // this.h = h;
      // this.m = m;
      // this.s = s;
      return {
        h,
        m,
        s,
        hm
      }
    },
  }
}

export default mixinCountDown;


